<template>
  <div>
  <div>
    <div class="has-background-secondary">
      <div class="container content py-6 mb-6">
        <h1 class="has-text-white my-0">Declaration of Digital Independence (DoDI)</h1>
      </div>
    </div>

    <div class="container content pb-6">
      <div style="max-width: 850px">
        
        <p>
            This DoDI has been written by one of our founders and is supported by the whole Plumb team. We believe that this is the foundation of a humane internet.
        </p>
        <p>
            Note: All quotes from Thomas Jefferson’s final draft of the Declaration of Independence.
        </p>
        <h2>Preamble</h2>
        <p>
            As the world moves into the digital age, a “decent respect to the opinions of mankind," and to the worth of every individual requires an acknowledgement of every persons' Digital Human Rights. As citizens of the digital world, we declare “the following truths to be self-evident”:
        </p>
        <h3>Article 1. Inherent Value</h3>
        <p>
            Each of us, as people, as humans, have inherent value. In the digital domain we are each represented by our data. It has inherent value, too.
        </p>
        <p>
            It should never be weaponized against us or used in any manner detrimental to our own “life, liberty, or pursuit of happiness.”
        </p>
        <p>
            That is Digital Tyranny. Our data deserves the same respect as each one of us do as people.
        </p>
        <p>
            It is a Digital Human Right to have agency over one’s own data, just as we have agency over how we exercise our individual inherent value.
        </p>

        <h3>Article 2. Search is a Digital Human Right</h3>
        <p>The mass of human knowledge has been digitized and the mass of human interactions are performed digitally, making search as essential as air.</p>
        <p>It is, thus, a Digital Human Right.</p>
        <p>Just as every person deserves access to "life, liberty, and the pursuit of happiness," each of us has a human right to freely access our heritage; the extant collected wisdom of humanity and the on-going human conversation.</p>
        <p>That requires Independent Search. Search that is unbiased and uncommercialized.</p>
        <p>The highest good for search is to be a transparent window into our Digital Human Heritage. To be the internet’s card catalog.</p>
        <p>Digital Tyranny is not needed to provide that.</p>
        <p>Independent Search is needed to thrive in the digital universe, and everyone deserves the opportunity to thrive, to access our Digital Human Right to "life, liberty, and the pursuit of happiness,"</p>

        <p><strong>These are the main operating principals of Plumb</strong></p>




      </div>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: "Dodi"
}
</script>